.create-routine-prep {
  h2 {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    .transparent {
      font-size: 1.5rem;
      margin-top: 2rem;
    }
  }
}
