.new-exercise-container {
  position: relative;
  .new-exercise-content {
    display: grid;
    grid-template-columns: 25rem 25rem;
    column-gap: 1rem;
  }
  .new-exercise-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.shim-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
