.routine-selector {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  gap: 1.5rem;
}
.modal {
  .warning {
    color: #dd0000;
  }
  .delete-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .transparent {
      margin-right: 1rem;
    }
  }
}
