@use "../colors" as *;
@use "../typography" as *;

.dashboard-content {
  padding: 3rem 2.5rem;
  display: flex;
  height: 100vh;
  flex-direction: column;
  h2 {
    font-size: $header-size;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  .subtext {
    font-size: $subtext-size;
    color: $subtext-color;
    margin: 0;
    & > span:first-child {
      margin-right: 2em;
    }
    & > span {
      & > span {
        margin-right: 0.4em;
      }
    }
  }
  .patients-header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    h3 {
      font-size: $sub-header-size;
      margin: 3rem 0 0;
    }

    .select-component {
      margin-block: -0.6rem;
      margin-left: auto;
      margin-right: 1rem;
      * {
        margin: 0;
      }
      .select-container {
        /* display */
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
  .user-list {
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    gap: 1.5rem;
  }

  .center-spinner {
    margin: 0 auto;
    margin-top: 13rem;
  }
}

.container {
  // height: 50px;
  // position: relative;
  // flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 50% !important;
}

.vertical-center {
  // margin: 0;
  // position: absolute;
  // top: 50%;
  // left: 35%;
  // -ms-transform: translateY(-50%);
  // transform: translateY(-50%);
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // justifyContent: center;
  // alignItems: center;
  // text-align: center;

  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.add-patient {
	margin-left: 1em;
}

.delete-patient__modal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50% !important;

  .delete-patient__buttons-container {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;

    .confirm-delete__patient {
      background-color: $red-functional;
    }
  }
}

.add-patient__modal {
	.error {
		color: $red-functional;
	}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50% !important;

  .delete-patient__buttons-container {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;

    .confirm-delete__patient {
      background-color: $red-functional;
    }
  }
}
