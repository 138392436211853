.sheets-container {
  display: flex;
  flex-direction: column;
  min-width: 30rem;

  .modal-item-header {
		display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  h3 {
    margin: 0;
  }

	h4, p {
		margin: 0.4em 0;
	}

	.answer {
		display: block;
	}

  .question-header {
    display: flex;
    align-items: center;
    gap: .5rem;
    .red-value {
      width: 20px;
    }
  }
}
