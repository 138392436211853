.search-bar {
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid #dddddd;
  width: 40%;
  min-width: 18.75rem; //300px
  height: 2.5rem;
  font-size: 0.9375rem;
  .search-icon {
    color: #aaa3a3;
    padding: 0.5em;
  }
  .input {
    display: grid;
    flex: 1 1 auto;
    background-color: inherit;
    border: none;
    border-radius: inherit;
    min-width: 0;
    font-size: inherit;
    text-overflow: ellipsis;
    font-family: inherit;
    color: inherit;
    &:focus {
      outline: none;
    }
  }
}
