.home-svg {
  height: 1em;
  fill: none;
  path {
    stroke: currentColor;
    stroke-width: 0.083333rem;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
