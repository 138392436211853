@use "../colors";
@use "../typography";

.message-center {
  width: 27rem;
  border: 2px solid #dddddd;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
	height: 664px;
  flex-direction: column;

  .message-center-header {
    display: block;
    width: 100%;
    margin: 0;
    padding: 1rem;
    background-color: colors.$primary-base-color;
    color: #ffffff;
    border-bottom: 2px solid #dddddd;
  }

  .message-center-messages {
    flex-grow: 2;
    overflow: scroll;
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    .actual-message-list-container {
      overflow: auto;
      margin: 0;
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      width: 100%;
    }

    .message-component {
      margin: 0.25rem 1rem;
      width: max-content;
      max-width: calc(100% - 2rem);
      min-width: 1px;

      .message-info {
        font-size: 0.625rem;
        display: flex;
        justify-content: space-between;
        gap: 0.75em;
      }

      .message-text {
        border-radius: 1rem;
        padding: 0.5rem;
        text-align: left;
        width: max-content;
        max-width: 100%;
        font-size: 0.875rem;
      }

      &.sent {
        word-wrap: break-word;
        max-width: 22rem;
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .message-info {
          flex-direction: row;
        }

        .message-text,
        .mini-routine-card {
          border: 2px solid #a9c5ff;
          background-color: #d5dffd;
        }
      }

      &.recieved {
        align-self: flex-start;
        max-width: 23rem;
        word-wrap: break-word;

        .message-info {
          flex-direction: row-reverse;
        }

        .message-text,
        .mini-routine-card {
          border: 2px solid #dddddd;
          background-color: #f0f0f0;
        }
      }
    }

    .loaded-all-messages {
      font-size: 0.875rem;
      align-self: center;
      text-align: center;
      opacity: 0.8;
      margin-bottom: 5px;
    }
  }

  .message-center-footer {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    padding: 0 0.5rem;
    font-size: 1.5rem;
    background-color: #f0f0f0;
    border-top: 1px solid #dddddd;
    align-items: center;

    .message-center-input {
      flex-grow: 2;
      background-color: #ffffff;
      border: 1px solid #908888;
      border-radius: 1.125rem;
      margin: 0.5rem 0;
      height: min-content;
      display: flex;
      justify-content: left;
      padding: 0.25rem 1rem;
      transition: flex-grow 1s ease-in-out;
      z-index: 2;
      align-items: center;

      textarea {
        font-family: typography.$font-family;
        font-size: 1rem;
        border: none;
        background-color: transparent;
        resize: none;
        outline: none;
        padding: 0;
        height: 1.5rem;
        min-height: 0;
        width: inherit;
        min-width: 0;
        line-height: 1.5em;
      }
    }

    .material-icons,
    .material-icons-outlined {
      padding: 0.25rem;
    }

    .message-center-buttons {
      color: colors.$primary-base-color;
      transition: width 0.5s ease-in-out;
      width: 7rem;
      display: flex;
      gap: 0.5rem;

      &.hidden {
        width: 2rem;
      }

      .material-icons {
        width: 2rem;
        height: 2rm;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
      }
    }

    .message-center-button {
      color: colors.$primary-base-color;
      flex-grow: 1;
      border: none;
      outline: none;
      font-size: 1.5rem;
      padding: 0;
      border-radius: 0.5rem;
      cursor: pointer;
      position: relative;
      overflow: visible;

      .tool-tip {
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -75%;
        left: 0;
        width: max-content;
        max-width: none;
        height: 1rem;
        font-size: 0.625rem;
        z-index: 3;
        background-color: #443c3c;
        color: #f0f0f0;
        border-radius: 0.5rem;
        padding: 0.625rem 0.5rem;
        transition: opacity 0.25s ease-in-out;
      }

      &:hover {
        background-color: #dddddd;
        box-shadow: 0 0 0.25rem 0.25rem #dddddd;

        .tool-tip {
          opacity: 1;
        }
      }

      &:disabled {
        color: #908888;
        background-color: transparent;
        box-shadow: none;
      }
    }

    .modal {
      .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
      }
    }
  }

  .message_options_pick {
    border: solid 2px colors.$blue-lighter;
    background-color: colors.$blue-lightest;
    border-radius: 10px;
    height: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
      width: 50%;
      text-align: center;
    }

    .active {
      background-color: #000;
      font-weight: 500;
      color: white;
      padding: 0.2rem 0;
      border-radius: 8px;
    }
  }
}
