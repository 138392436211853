.questionnaire-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .material-icons-outlined {
    font-size: 1em;
  }
  .text-svg-container {
    margin-right: 0.4em;
  }
  .questionnaire-button {
    background-color: #def1df;
    color: #34a853;
  }
  .assessment-button {
    background-color: #fce6dd;
    color: #ff7848;
  }
}
.test-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .test-button{
    background-color: #ffffff;
    color: #000000;
    border:1.5px solid #000000;
    height:30px;
    // line-height: 100px;
  }
}
