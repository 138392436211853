@use "../../typography" as *;
@use "../../colors" as *;

.navbar-link {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 15.5rem; //248px
  min-height: 3.5rem; //56px
  background-color: $primary-base-color;
  color: white;
  font-size: $large-text-size;
  border: none;
  border-radius: 1rem;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  text-decoration: none;
  p {
    margin: 0;
    padding: 0.8rem 0;
  }

  &.fancy {
    background-color: $primary-1-color;
		color: black;
    border-left: 0.5rem solid white; // 8px
    border-radius: 0.25rem; // 4px
    .button-content {
      margin: 0.75rem auto; //12px
    }
  }
  
  .ping {
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    margin-left: 10rem;
    border-radius: 50%;
    background-color: #ff4848;
  }
}
