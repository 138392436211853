.recent-notes {
  .recent-notes__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      cursor: pointer;
    }
  }
  h4 {
    margin: 0;
  }

  background-color: white;
  border: solid 1px #ddd;
  border-radius: 8px;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
  scrollbar-width: none;
  overflow-y: scroll;
  scrollbar-width: thin;
  max-height: 24rem;
  max-width: 20rem;
  min-width: 50%;

  .note-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    align-self: center;
    width: 95%;
  }

  .note {
    background-color: #d5dffd;
    border: 1px solid #a9c5ff;
    border-radius: 4px;
    padding: 0.2rem;
    line-height: 14px;
    word-wrap: break-word;
    font-size: 0.875rem;
    max-width: 20rem;
  }

  .no-notes {
    font-size: 0.875rem;
  }

  .add-trainerNotes__modal {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;

    .trainerNotes__buttons-container {
      display: flex;
      justify-content: flex-end;
      p {
        text-align: center;
      }
    }
  }
}

.recent-notes::-webkit-scrollbar {
  display: none;
}
