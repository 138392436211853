@mixin sets-and-reps-modal {
  .modal {
    h3 {
      margin: 0;
    }

    fieldset {
      border: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      gap: 1.5rem; // 24px

      * {
        flex: 0 1 auto;
        min-width: 0;
      }

      .text-input-component {
        margin: 1.5rem 0 2rem; // 24px 0 32px
        width: 13.5rem; // 216px

        .label {
          margin-top: 0;
        }

        .wrapper {
          width: auto;
        }
      }
    }
  }
}
