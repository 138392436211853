@use "../../colors" as *;
@use "../../typography" as *;

.graph-container {
  background-color: white;
  width: 100%;
  height: 8rem;
  border: solid 1px #ddd;
  border-radius: 8px;
  padding: 0.5rem 0.5rem;


  h4 {
    margin: 0;
  }

  .graph-range__container {
    display: flex;
    align-items: center;
    height: 90%;
    padding-bottom: .5rem;


    .graph-range__numbers {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      font-size: .6rem;
      text-align: center;
      padding-right: .1rem;
    }
  }

  .graph-range {
    border-top: 1px #ddd solid;
    border-bottom: 1px #000 solid;
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 .25rem;

    .graph-item {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
  }
}
