@use "../colors" as *;

.toggle {
  cursor: pointer;
  --dot-size: 1.25em;
  --track-length: calc(var(--dot-size) * 1.5);

  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
  padding: calc(var(--dot-size) / 6);

  input[type="checkbox"] {
    all: unset;
    max-width: 0;
    min-width: 0;
    opacity: 0;
    overflow: hidden;
  }
}

.slider {
  /* display */
  display: inline-flex;
  align-items: center;

  /* positioning */
  /* box-model */
  width: var(--track-length);
  background-color: #DDD;
  height: calc(var(--dot-size) * 0.75);
  border-radius: 100vmax;
  margin-inline: calc(var(--dot-size) / 6);

  /* typography */
  /* manipulation */

  /* misc */
  user-select: none;

  :checked + & {
    background-color: $blue-lighter;
  }
}

.slider::before {
  content: "";
  display: inline-block;
  width: var(--dot-size);
  height: var(--dot-size);
  background: rgb(164, 161, 161);
  margin: calc(-1 * var(--dot-size) / 6);
  transition: 0.05s ease-in-out transform;
  border-radius: 100vmax;

  :checked + & {
    transform: translateX(
      calc(var(--track-length) - var(--dot-size) / 2 - var(--dot-size) / 6)
    );
    background-color: $primary-base-color;
  }
}
