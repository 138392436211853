$profile-default-background: #eeeeee;
$profile-default-lines: #aaaaaa;
$profile-default-size: 7rem;
.profile-photo {
  height: $profile-default-size;
  width: $profile-default-size;
  margin: 2.625rem;
  font-size: $profile-default-size;
  border-radius: 999rem;
  border: 1px solid $profile-default-lines;
  &:not(.profile-photo-default) {
    display: inline-flex !important;
  }
  img {
    width: auto;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    margin: -0.0125em;
    object-fit: cover;
    border-radius: 999rem;
    background-color: $profile-default-background;
  }
}

.profile-photo-default {
  overflow: hidden;
  stroke-width: 0.125rem;
  background-color: $profile-default-background;
  .light-fill {
    fill: $profile-default-background;
  }
  .med-fill {
    fill: $profile-default-lines;
  }
  .even-odd {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }
  .white-fill {
    fill: white;
  }
  .med-stroke {
    stroke: $profile-default-lines;
  }
}
