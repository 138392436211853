@use "../colors" as *;

.schedule__main {
  padding: 0.5rem 2.5rem 0 2.5rem;
  h2 {
    font-size: 3rem;
    margin: 0;
  }

  .schedule-filter {
    label {
      margin-top: 0;
    }
    .select-container {
      padding: 0.8rem;
      margin-top: 0;
    }
  }

  .scheduling-options {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
    button {
      margin-top: 0;
    }
  }

  .view-schedule-btn {
    border-left: none;
    text-align: center;
    // margin-bottom: 0.5rem;

    &:hover {
      background-color: darken($primary-base-color, 7%);
    }
  }

  .view-calendar {
    margin-top: 1rem;
    margin-left: 0;
  }

  .schedule-wrapper {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-left: 3rem;

    .search-container {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .schedule-patients__container {
    display: flex;
    margin-top: 2rem;
    flex-wrap: wrap;
    // justify-content: center;
    gap: 1rem;
    max-width: 80vw;
    p {
      margin: 0;
      margin-left: 0.5rem;
      font-weight: 500;
    }

    .schedule-patient {
      width: 25%;
      display: flex;
      padding: 0.5rem;
      align-items: center;
      justify-content: flex-start;
      border-radius: 10px;
      border: solid 1px #ddd;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      .schedule-patient__picture {
        height: 4rem;
        width: 4rem;
        margin: 0;
        border: solid 2px white;
      }

      &:hover {
        background-color: #d5dffd;
        border: solid 1px #d5dffd;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .back-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: none;
    cursor: pointer;
    background: none;
    color: #999;
    width: 8rem;

    svg {
      width: 1.5rem;
    }
  }
}
