@use "../colors" as *;
@use "../typography" as *;
@use "../components/button";
@use "../mixins";

.routine-content {
  height: 100vh;
  display: grid;
  grid-template:
    "nav nav" auto
    "header header" auto
    "subheader subheader" auto
    "selected available" 1fr / 1fr 1fr;
  padding: 1rem 2.5rem 3.5rem 2.5rem;
  .navigation-bar {
    grid-area: nav;
    a {
      text-decoration: none;
      color: inherit;
      &:visited {
        color: inherit;
      }
    }
    .current-route {
      color: $primary-base-color;
    }
  }
  .header {
    grid-area: header;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    overflow: auto;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    .routine-details {
      display: inline-flex;
      flex-direction: column;
      min-width: 0;
      h2 {
        font-size: 3rem;
        margin-bottom: 0;
        min-width: 0;
      }
      .routine-assignment {
        margin-top: 1rem;
        font-size: $large-text-size;
        font-weight: 500;
        .profile-photo {
          height: 1em;
          width: 1em;
          font-size: 1em;
          margin: -0.1em 0.4em;
        }
        .patient-name {
          color: $primary-base-color;
          text-decoration: none;
          &:visited {
            color: $primary-base-color;
          }
        }
      }
    }
    .routine-save {
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: end;
      margin-top: 3rem;

      .template-check {
        display: flex;
        align-items: flex-end;
        min-height: 3rem;
        p {
          margin: 0;
        }
        .template-check-label {
          max-width: 21.75rem;
          margin-top: 1rem;
          display: flex;
          align-items: center;
          input {
            display: block;
            margin: 0 1rem;
          }
        }
      }

      .routine-buttons {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }
    }
  }
  .routines-header-container {
    grid-area: subheader;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    .routines-pre-header {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      padding-top: 2.5rem;
      width: 50%;
      // flex-direction: column;
      // align-items: start;
      // justify-content: start;
      h3 {
        font-size: $sub-header-size;
        font-weight: 700;
        margin: 0 1em 0 0;
      }
      .create-exercise-button {
        .button-content {
          display: flex;
          align-items: center;
          .material-icons {
            margin-right: 0.25rem;
          }
        }
      }

      .tooltip {
        animation: fadeIn 800ms;
        width: 15rem;
        background-color: #ffb2b2;
        color: #fb1e1e;
        font-weight: 600;
        filter: drop-shadow(0px 0px 1px rgba(34, 34, 34, 0.5));

        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        top: 100%;
        font-size: 0.75rem;
        left: 15%;
        margin-left: -60px;

        position: absolute;
        z-index: 1;
        &::after {
          content: " ";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #ffb2b2 transparent transparent transparent;
        }
      }
    }

    .exercise-options {
      display: flex;
      justify-content: center;
      padding-left: 1.5rem;
      width: 50%;
      .edit-routine__searchbar {
        width: 100%;
        margin-left: 1rem;
      }
    }

    .searchbar {
      align-self: flex-end;
    }
    .schedule-routine-button {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
  .selected-exercises {
    display: flex;
    flex-flow: column;
    border: 2px solid #dddddd;
    border-radius: 1rem;
    overflow: auto;
    margin-top: 1.5rem;
    gap: 0.75rem;

    .exercise {
      padding: 0.25rem 1rem 1rem 1rem;
    }

    .schedule-routine {
      min-height: 4rem;
      width: auto;
      background-color: $primary-base-color;
      display: flex;
      align-items: center;
      padding-left: 1rem;
      font-size: 1.3rem;

      .frequency-set {
        display: flex;
        align-items: center;
        color: white;

        span {
          font-weight: 700;
          padding-left: 1rem;
          margin-right: 0.5rem;
        }
      }

      .weekly-frequency-set {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: white;
        gap: 0.5rem;
        padding-left: 0.5rem;

        span {
          font-weight: 700;
        }
      }

      .no-frequency {
        color: #fff;
        display: flex;
        align-items: center;
        span {
          padding-left: 1rem;
          margin-right: 0.5rem;
        }

        svg {
          cursor: pointer;
        }
      }
    }

    .drop-location {
      width: calc(100% - 2.75rem);
      height: 0.5rem;
      border-radius: 1rem;
      box-shadow: inset 0 0 0.25rem 0.25rem #ffffff;
      background-color: #eeeeee;
      transition: all ease-in-out 0.15s;
      opacity: 0;
      margin-left: 2.75rem;
      flex-shrink: 0;
      &.dragging {
        opacity: 1;
        height: 2.5rem;
        &.dragging-over {
          background-color: #cccccc;
        }
      }
      .drop-filler {
        width: 2.75rem;
      }
      .drop-display-area {
        flex: 1;
      }
    }
    .routine-instructions {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      text-align: center;
      img {
        height: 7.125rem;
      }
      .routine-instructions-text {
        margin-top: 2rem;
      }
    }
  }
  .available-exercises, .available-exercises-full {
    display: flex;
    flex-flow: column;
    overflow: auto;
    padding: 1rem;
    margin-top: 1.5rem;
    gap: 2rem;
  }

	.available-exercises-full {
		grid-column: span 2;
	}

	.available-exercises-full .handle {
		display: none;
	}

  @include mixins.sets-and-reps-modal;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
