@use "../colors" as *;
@use "../typography" as *;

.patient-info__container {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  color: $subtext-color;
  padding-left: 5.5rem;

  .patient-info__header {
    font-weight: 700;
    margin-right: 0.25rem;
  }
}
