@use "../colors" as *;
@use "../typography" as *;

.patient-reassess {
	text-align: center;
}

.patient-details-content {

	.stacked-container {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2em;
	}

  padding: 1rem 0 0 2.5rem;
  .navigation-bar {
    a {
      text-decoration: none;
      color: inherit;
      &:visited {
        color: inherit;
      }
    }
    .current-route {
      color: $primary-base-color;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    padding-right: 2.5rem;
    .user-details {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .profile-photo {
        height: 4rem;
        width: 4rem;
        font-size: 4rem;
        margin: 0 1.5rem 0 0;
      }
      h2 {
        font-size: 3rem;
        margin-right: 0.5em;
        margin-bottom: 0;
        margin: 0 0.5em 0 0;
        white-space: nowrap;
      }
      .patient-stats {
        height: 2.625rem;
        font-size: $large-text-size;
        .patient-streak,
        .patient-adherence {
          margin-right: 1em;
        }
        .subtext {
          font-size: 0.875rem;
        }
      }
    }
  }
  .patient-details-container {
    display: grid;
    overflow: auto;
    grid-template-columns: auto;
    margin: 1em 0;
    min-height: 1;
    gap: 1.5rem;
    padding-right: 2.5rem;
    
    .routine-selector {
      padding-bottom: 2rem;
    }
  }
  .patient-info__container-wrapper {
    .warning-text__bg {
      background-color: #ffb2b2;
      color: #fb1e1e;
    }
    .warning-text__container {
      margin: 1rem 0;
      margin-left: 5.5rem;
      padding: 0.35rem;
      font-weight: 600;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 0.3rem;

      width: fit-content;
      height: 2rem;
      p {
        margin: 0;
      }
      .warning-text-svg {
        width: 20px;
      }

      .close-warning-text-svg {
        width: 12px;
        cursor: pointer;
      }
    }
  }

  .patients-calendar-button {
		width: 100px;
	}

  .patients-header-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    grid-column: span 2;
    .routines-pre-header {
      display: flex;
      align-items: center;
      h3 {
        font-size: 2rem;
        font-weight: 700;
        margin: 0;
        margin-right: 1em;
      }
    }
  }
  .routine-selector {
    grid-column: span 2;
    height: fit-content;
  }
}
