@use "../typography" as *;
@use "../colors" as *;

.login-main {
  width: 31.875rem; //510px
  height: 100vh;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 3rem auto;
    color: $primary-base-color;
    height: $login-header-size;
  }
  .text-input-component {
    .wrapper {
      .text-svg-container {
        opacity: 50%;
        height: 1.5rem;
        margin: 1.1em;
      }
      width: 35rem;
    }
  }

  .login-button {
    margin: 2em auto 1.5rem;
  }

  .forgot-password-button p {
    margin: 0;
  }
}
