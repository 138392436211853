.settings-page {
  justify-self: center;
  align-self: center;
  h3 {
    margin-bottom: 1.5em;
  }

  fieldset {
    display: flex;
    gap: 2rem;
    align-items: center;

    padding: 0;
    margin: 0 0 2rem 0;
    border: 0;

    span {
      margin-right: 0.5rem;
      color: #3e7efe;
    }

    + fieldset {
      margin-top: 1em;
    }

    legend {
      padding: 0;
      margin-bottom: 1rem;
    }

    > label {
      gap: 0.25rem;
    }
  }

  .settings-save__btn {
    margin-top: 2rem;
    width: 4rem;
    text-align: center;
  }
}
