.doughnut-chart__container {
  width: 50%;
  height: 15rem;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    margin: 0;
  }
  background-color: white;

  .circular-chart {
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
    margin-top: 0.25rem;
    width: 100%;

    .circle-bg {
      fill: none;
      stroke: #ddd;
      stroke-width: 4;
    }
    .circle {
      fill: none;
      stroke-width: 4;
      animation: progress 1s ease-out forwards;
    }
    .percentage {
      font-weight: 700;
      font-size: 0.5rem;
      text-anchor: middle;
    }
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
}
