@use "../typography";

.select-component {
  line-height: 1.6rem;
  font-family: typography.$font-family;

  label {
    display: block;
    margin: 1.5em 0 0;
    font-weight: 700;
    font-size: typography.$subtext-size;

    .select-container {
      padding: 0.5rem 1rem;
      margin-top: 0.5em;
      border: 2px solid #dddddd;
      border-radius: 0.9375rem;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;

      select {
        border: none;
        outline: none;
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
        flex: 1 1 auto;
      }
    }
  }
}