@use "../colors";
@use "../typography";

.add-exercise-modal {
  width: 550px !important;

  .text-input-component {
    width: 33.33%;
  }

  .select-component {
    width: 33.33%;
  }

  .select-component label {
    width: 100%
  }

  .select-component label .select-container {
    width: 100%;
    height: 40px;
    border-radius: 10px;
  }

  .text-input-component .wrapper .input {
    width: 100%;
  }

  .text-input-component .wrapper {
    height: 40px;
    border-radius: 10px;

  }

  .sets-container {
    width: 100%;
    display: flex;
    gap: 1rem;

    .wrapper {
      width: auto;
    }
  }

  .notes-label {
    display: block;
    margin: 1.5em 0 0.5em;
    font-weight: 700;
    font-size: 1rem;
  }

  .modal-buttons {
    margin-top: 2.5rem;

    .save-button {
      margin: 0;
      min-width: 150px;
      border-radius: 10px;
      height: 40px;
    }

    .cancel-button {
      margin-left: 1rem;
      border: 1px solid #000000;
      width: 150px;
      border-radius: 10px;
    }
  }

}