@use "../colors" as *;

.feedback-container {
  margin: 2rem;
  width: 100%;

  .navigation-bar {
    margin-bottom: 1rem;
  }
}

.feedback-request-overview {

  border-radius: 1em;
	background: #f0f0f0;
	border-radius: 1rem;
	padding: 1rem;
  width: 100%;
	border: 2px solid $primary-1-color;

  display: grid;
  grid-template-areas: "detail video"
                       "detail video"
                       "submit submit";

  button { 
    grid-column: 2;
    margin-left: auto;
  }
}

.feedback-video-container {
  grid-area: "video";
}

.feedback-details {
  grid-area: "detail";
}
