@use "../../colors" as *;

.new-badge {
	background: #0377fc;
	color: white;
	font-size: 0.8em;
	padding: 0.15em 0.4em;
	border-radius: 4px;
}

.sidebar-layout {
  display: grid;
  height: 100%;
  width: 100vw;
  grid-template-columns: 16.75rem auto; // 268px
  grid-template-areas: "side main";
  .sidebar {
    height: 100%;
    background-color: $primary-base-color;
    text-align: center;
    padding: 0.375rem 0 0.25rem; // 6px 0 4px
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    width: 100%;
    .sidebar-top {
      height: auto;
      h1 {
        height: 2.5rem; //40px
        color: white;
        font-size: 2rem; //32px
        margin: 3rem auto 0; //48px auto 0
      }
      .profile-photo {
        margin: 1.5rem auto; // 24px auto
      }
      .button-component, .navbar-link {
        min-height: 0;
      }
    }
    .sidebar-bottom {
      height: auto;
    }
    .button-component, .navbar-link {
      margin: 0.75rem 1.5rem; //12px 24px
      width: 13.75rem; //220px
      min-width: 0;
    }

    .nav-links_container {
      margin-top: 3rem;
    }
  }
  .sidebar-main-content {
    height: auto;
  }
}
