.incognito-input-wrapper {
  white-space: nowrap;
  min-width: 0;
  display: inline-flex;
  width: 100%;

  .error {
    border-bottom: 0.0625rem solid red;
  }
  .error-text {
    position: relative;
    color: red;
    top: 2.4rem;
    // margin-top: 6rem;
    font-size: 12px;
  }

  .input-formatter {
    display: inline-block;
    position: relative;
    min-width: 1em;
    width: min-content;
    .input-formatter-span {
      visibility: hidden;
      white-space: pre;
      padding: 0 0.2em;
    }
  }
  input {
    position: absolute;
    background: transparent;
    border: none;
    font-size: inherit;
    width: 100%;
    text-overflow: ellipsis;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
    &:focus {
      outline: none;
      border-bottom: 0.0625rem solid #dddddd;
    }
  }
  .edit-button {
    transition: opacity 0.3s;
    width: 1em;
    span {
      width: 100%;
    }
    &.visible {
      opacity: 0.3;
    }
    &.invisible {
      opacity: 0;
    }
  }
}
