$status-height: 4em;

.patient-stats {
  height: 2rem;
  display: flex;
  justify-content: space-around;
  text-align: left;
  h5 {
    margin: 0;
  }
  .stat-logo {
    margin-right: 0.5em;
  }
  .patient-streak,
  .patient-adherence {
    height: 100%;
    display: flex;
    .streak-details,
    .adherence-details {
      display: flex;
      flex-direction: column;
    }
    .subtext {
      font-size: 0.625rem;
      margin: 0;
      opacity: 0.75;
      white-space: nowrap;
    }
  }
}
