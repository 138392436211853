@use "../../colors" as *;

.schedule-calendar__container {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border: solid #ccc 1px;
  border-radius: 10px;
  height: 80vh;
  overflow: auto;

  .calendar-day {
    min-width: 14rem;
    border-left: 1px solid #ccc;
    border-right: none;
    padding: 1rem;
    overflow: auto;

    h4 {
      text-align: center;
      margin-top: 0;
      border-bottom: 1px solid #bbb;
    }

    &:first-child {
      border-left: none;
    }

    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 100%;
      background: #fff;
    }

    &::-webkit-scrollbar-track {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background-color: #f6f6f6;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #c3bfbf;
    }

    .appointment {
      p {
        margin-bottom: 0;
        font-weight: 400;
      }

      .appointment-type {
        display: block;
        font-size: .8rem;
        color: $subtext-color;
        font-weight: 500;
      }
      .appointment-time {
        font-size: 0.8rem;
        color: $primary-base-color;
        letter-spacing: 0.05em;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }
}
