@use "../../colors" as *;
.eye-svg {
  width: 24;
  height: 24;
  path {
    stroke: $text-color;
    stroke-width: 0.125rem; //2px
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
