@use "../colors";
@use "../typography";

.textbox-container {
  .notes-label {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    margin: 1.5em 0 0.5em;
  }
  .notes-input {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-grow: 2;
    background-color: #ffffff;
    border: 2px solid #ddd;
    // border-radius: 1.125rem;
    border-radius: 10px;
    margin: 0.5rem 0;
    height: 80px;
    padding: 0.25rem 1rem;
    z-index: 2;
    transition: flex-grow 1s ease-in-out;
    textarea {
      font-family: typography.$font-family;
      font-size: 1rem;
      border: none;
      background-color: transparent;
      resize: none;
      outline: none;
      padding: 0;
      height: 100%;
      min-height: 0;
      width: 100%;
      line-height: 1.5em;
    }
  }
}
