@use "../typography" as *;

.chat-page__content {
  padding: 0.5rem 2.5rem 0 2.5rem;
  .chat-header {
    font-size: 3rem;
    margin: 0 0 1rem 0;
  }

  .chat-container {
    border: solid #c3bfbf 1px;
    border-radius: 16px;
    min-height: 80vh;
    display: flex;
    justify-content: space-between;

    .chat-sidebar {
      max-width: 20rem;
      border-right: solid #c3bfbf 1px;
      height: 100%;
      min-height: 80vh;
    }

    .chat-searchbar {
      color: #aaa3a3;
      padding-left: 1rem;
      display: flex;
      gap: 0.4rem;
      align-items: center;
      svg {
        width: 20px;
      }

      .chat-patient__search {
        width: 100%;
        height: 100%;
        padding: 0.6rem 0;
        font-size: 1rem;
        font-weight: 500;
        color: #000;
        font-family: $font-family;
        border: none;
        outline: none;

        &:focus {
          outline: none;
        }
      }
    }

    .chat-patient__picture {
      height: 4rem;
      width: 4rem;
      margin: 0;
      border: solid 2px white;
    }

    .chat-patients__list {
      margin: 0;
      margin-right: 0;
      list-style-type: none;
      padding: 0;
      border-top: solid #c3bfbf 1px;
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 81vh;
      .chat-patient {
        padding: 0.75rem 0.75rem;
        height: 5rem;
        border-bottom: solid #c3bfbf 1px;
        display: flex;
        align-items: center;
        gap: 1rem;

        .chat-patient__info {
          align-self: flex-start;
          width: 100%;
          .chat-patient__header {
            display: flex;
            justify-content: space-between;
            span {
              font-size: 0.7rem;
              font-weight: 500;
            }
          }
          .chat-patient__name {
            color: #000;
            font-weight: 700;
            font-size: 1.25rem;
            margin: 0;
          }

          .recent-msg__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .most-recent__msg {
              font-size: 0.875rem;
            }

            .ping {
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
              background-color: #ff4848;
            }
          }
        }
        &:last-child {
          border-bottom: none;
          border-bottom-left-radius: 19px;
        }
      }

      &::-webkit-scrollbar {
        width: 0.5rem;
        height: 100%;
        background: #fff;
      }

      &::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background-color: #f6f6f6;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #c3bfbf;
      }
    }

    // main chat area
    .chat-center__main {
      flex-grow: 1;
      height: 87vh;

      .message-center {
        width: 100%;
        height: 100%;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 1px solid #c3bfbf;
        border: none;

        .chat-page__patient-header {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 0.85rem 1rem;

          .chat-patient__picture {
            width: 2rem;
            height: 2rem;
            border: solid 3.5px white;
          }
        }

        h3 {
          padding: 0.9rem 1rem;
        }

        .patient-name {
          font-weight: 700;
        }

        #message-input {
          width: 100%;
        }

        .message-center-footer .message-center-button {
          flex-grow: 0;
        }
      }
    }
  }
}
