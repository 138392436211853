@use "../../colors" as *;
.text-svg-container {
  display: inline-block;
  height: 100%;
  line-height: 100%;
  text-align: center;
  vertical-align: middle;
  opacity: inherit;
  svg {
    overflow: visible;
  }
}
