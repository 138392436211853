.text-input-component {
  .text-input-items {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    max-width: 25rem;
    margin: 0.25rem;
    // row-gap: .25rem;
    .text-list-input-item {
      font-size: 1rem;
      border-radius: 999rem;
      background-color: #f0f0f0;
      padding: 0.25em 0.5em;
      position: relative;
      &:hover {
        .item-remove {
          opacity: 1;
        }
      }
      .item-remove {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        transition: all 0.2s ease-in-out;
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .material-icons {
          background-color: #f0f0f0dd;
          border-radius: 200rem;
          box-shadow: 0 0 2px 2px #f0f0f0dd;
          cursor: pointer;
          padding: 0.25rem;
        }
      }
    }
  }
}
