.days-worked__container {
  h4 {
    margin: 0;
    margin-left: 0.5rem;
  }
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  border: 1px solid #ddd;

  .days-worked__calendar {
    margin-left: 0.5rem;
    overflow: auto;
    max-height: 13.5rem;
    overflow: auto;
    scrollbar-width: none;
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      display: none;
    }

    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    align-items: center;
    justify-content: flex-start;

    .day-item {
      font-size: auto;
      background-color: #c3bfbf;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;
      color: white;
    }

    .active {
      background-color: #34a853;
      color: white;
    }

    .year-text {
      font-size: 0.9rem !important;
    }
  }
}
