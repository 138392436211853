@use "../typography" as *;

.exercise-base-container {
  border: 2px solid #dddddd;
  border-radius: 1rem;
  display: flex;
  flex-grow: 1;
  min-width: 0;
  height: 5.125rem;
  position: relative;
  overflow: hidden;
  .exercise-photo {
    max-width: 8.125rem;
    height: 100%;
    .actual-photo {
      display: flex !important;
      width: 8.125rem;
      height: 100%;
      overflow: hidden;
      justify-content: center;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      img {
        height: 100%;
        display: block;
      }
    }
  }
  .exercise-info {
    padding: 1rem;
    min-width: 0;
    flex: 1;
    font-size: $subtext-size;
    font-weight: 400;
    .exercise-name {
      display: flex;
      justify-content: space-between;
      h4 {
        display: block;
        margin: 0;
        font-size: $large-text-size;
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      p {
        margin: 0 0 0 0.25rem;
        opacity: 0.8;
        font-weight: 700;
        .logo-svg {
          display: block;
          height: 0.75rem;
        }
      }
    }
    .exercise-details {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .subdetails + .subdetails {
        margin-left: 1rem;
      }
    }
  }
  &:hover {
    .exercise-edit-buttons {
      opacity: 0.9;
    }
  }
  .exercise-edit-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #eeeeee;
    box-shadow: 0 0 0.5rem 0.5rem #eeeeee;
    opacity: 0;
    padding-right: 1rem;
    .material-icons-outlined {
      width: 2rem;
      height: 2rem;
      font-size: 2rem;
      margin: 0.5rem;
      &:hover {
        opacity: 1;
        background-color: #ffffff;
        box-shadow: 0 0 0.25rem 0.25rem #ffffff;
        cursor: pointer;
      }
    }
  }
}
