.typeform-question-and-answer {
  .typeform-question {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
  }

  .errorColor {
    color: #ff4848;
  }
  h3 {
    margin: 0;
    display: inline-block;
    padding-right: 0.5rem;
  }
  .typeform-response {
    white-space: pre-wrap;
    padding-left: 1rem;
  }

  .red-value {
    width: 20px;
  }
}

.modal-content {
  .spacer {
    display: block;
    width: 100%;
    margin: 2rem 0;
    height: 1px;
    border-bottom: 1px solid grey;
  }

  .rom-warning__container {
    background-color: #ffb2b2;
    color: #fb1e1e;
    width: fit-content;
    padding: 0.5rem;
    border-radius: 5px;
    margin: 1rem 0;
    p {
      margin: 0;
      font-weight: 600;
    }
  }
}

.typeform-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin: 0;
  }
  .minimal {
    margin: 0;
    text-align: center;
  }
}
