@use "../colors" as *;
@use "../typography" as *;
@use "../components/button";
@use "../mixins";

.schedule-routine__container {
  h3 {
    font-size: 2rem;
  }
  h4 {
    margin-bottom: 0;
  }

  .routine-input {
    width: 100%;
    margin-bottom: 2rem;

    input {
      flex: none !important;
      display: block !important;
    }
  }

  .daily-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-bottom: 3rem;

    .daily-picker {
      width: 4.5rem;

      input {
        padding-right: 0.5rem !important;
      }
    }

    span {
      margin-top: 1rem;
    }
  }

  .pick-day__container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 4rem;
    cursor: pointer;
    // margin-bottom: 4rem;
  }
  .pick-day__item {
    background-color: #c3bfbf;
    text-align: center;
    line-height: 3.5rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
  }

  .picked {
    background-color: #34a853;
    color: white;
  }
}

.frequency-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0.5rem 0;
  border-radius: 8px;
  margin: 1.5rem 0;
  text-align: center;
  font-weight: 500;
  color: black;
  span {
    width: 13rem;
    cursor: pointer;
  }

  .active {
    margin: -8px -1px;
    padding-top: 8px;
    border-radius: 8px;
    color: white;
  }
}
.blue {
  background-color: $blue-lightest;
  border: solid 2px $blue-lighter;

  .active {
    background-color: $primary-base-color;
  }
}

.green {
  background-color: #bdebc9;
  border: solid 2px #92dda6;

  .active {
    background-color: #34a853;
  }
}

.yellow {
  background-color: #feecc3;
  border: solid 2px #fdd986;

  .active {
    background-color: #fcb514;
  }
}

// button styles
.schedule-routine__btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2rem;

  button {
    min-width: 9rem;
    border-radius: 16px;
  }

  .cancel-button {
    background-color: white;
    color: black;
  }

  .blue-btn {
    background-color: $primary-base-color;
  }
  .yellow-btn {
    background-color: #fcb514;
  }
  .green-btn {
    background-color: #34a853;
  }
}
