.selected-exercise-wrapper {
  display: flex;
  align-items: center;
  .handle {
    padding: 1.25rem;
    padding-left: 0.25rem;
    cursor: pointer;
  }
  .exercise-base-container {
    cursor: pointer;
  }
}
