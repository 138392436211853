.progress-svg {
  height: 100%;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke 0.5s ease-in-out;
  stroke-width: 1.25rem;
  .background-circle {
    stroke: #f0f0f0;
  }
  .progress-circle {
    stroke-width: inherit;
    stroke-dasharray: 192 200;
  }
}
