//Text colors
$placeholder-color: #999999;
$text-color: #333333;
$placeholder-opacity: 0.5;
$subtext-color: #908888;

//Main colors;
$primary-base-color: #101010;
$primary-1-color: #dddddd;
$blue-lighter: #333;
$blue-lightest: #ddd;
$success-color: #1ebd53;
$error-color: #ff0000;
$error-light-color: #ffcccc;
$red-functional: #FF4848;
