@use "../colors";

.file-upload-container {
  h4 {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    margin: 1.5em 0 0.5em;
  }
  .upload-file-container {
    width: 25rem;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #dddddd;
    border-radius: 0.5rem;
    padding: 4rem 2rem;
    position: relative;
    .file-preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      &:hover {
        .file-buttons {
          display: flex;
        }
      }
      img {
        width: 15rem;
      }
      video {
        width: 20rem;
      }
      .file-buttons {
        top: 0;
        right: 0;
        height: 5rem;
        width: 5rem;
        position: absolute;
        justify-content: center;
        align-items: center;
        display: none;
        background-color: #ddddddaa;
        box-shadow: 0 0 0.25rem 0.25rem #ddddddaa;
        .material-icons-outlined {
          display: block;
          color: #333333;
          cursor: pointer;
          &:hover {
            background-color: #eeeeee77;
            box-shadow: 0 0 0.5rem 0.5rem #eeeeee77;
          }
        }
      }
    }

    .material-icons-outlined {
      color: #888888;
      font-size: 3rem;
    }
    .upload-label {
      text-align: center;
      .upload-label-main {
        color: colors.$primary-base-color;
      }
      &:hover {
        .upload-label-main {
          color: darken(colors.$primary-base-color, 15%);
        }
      }
      .upload-input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        display: block;
        cursor: pointer;
      }
    }
    .loading {
      width: fit-content;
      display: flex;
      flex-direction: column;
      .loading-text {
        display: flex;
        align-items: centera;
        .material-icons-outlined {
          font-size: 1.5rem;
        }
      }
      .loading-bar-outside {
        height: 8px;
        width: 100%;
        background-color: #dddddd;
        overflow: hidden;
        border-radius: 200rem;
        position: relative;
        .loading-bar-progress {
          height: 100%;
          width: 100%;
          position: absolute;
          background-color: colors.$primary-base-color;
          top: 0;
          right: 100%;
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }
  .get-filename {
    margin-top: 1rem;
    display: block;
    .file-name-input {
      border: none;
      outline: none;
      font-family: inherit;
      font-size: 1.25rem;
      border-bottom: 1px solid #888888;
      margin-left: 1rem;
      margin-top: 0.5rem;
      transition: all 0.25s ease-in-out;
      &:focus {
        background-color: #f3f3f3;
        box-shadow: 0 0 3px 3px #f3f3f3;
      }
    }
  }
  .file-upload-buttons {
    margin-top: 1rem;
    button {
      font-size: 1rem;
    }
  }
}
