@use "../../colors" as *;
@use "../../typography" as *;

.patient-detailed-stats {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
	height: fit-content;
  padding: 1rem;
  border-radius: 1rem;
  border: 2px solid #dddddd;
  background-color: #f0f0f0;
	max-width: 800px;
  h3 {
    margin: 0;
  }

  .patient_stats__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }

  .statistics-container__wrapper {
    display: flex;
    justify-content: space-between;
  }

  .statistics-container__left {
    display: flex;
    flex-direction: column;
    // flex-grow: 3;
    width: 66%;
    gap: 1rem;

    .dougnut-container {
      display: flex;
      gap: 1rem;
    }
  }

  .statistics-container__right {
    display: flex;
    flex-direction: column;
    width: 32%;
    margin-left: 1rem;
    gap: 1rem;
    max-height: 32.5rem;
    // flex-shrink: 30;
  }

  .patient_stats__pick-time {
    border: solid 2px $blue-lighter;
    background-color: $blue-lightest;
    border-radius: 8px;
    padding: 0.2rem 0;
    height: fit-content;
    cursor: pointer;
    span {
      padding: 0 2rem;
    }

    .active {
      background-color: #000;
      font-weight: 500;
      color: white;
      margin: -8px -1px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 8px;
    }
  }

  .promise-score__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border: solid 1px #DDD;
    border-radius: 8px;
    padding: .5rem .5rem;

    h4 {
      margin: 0;
    }

    input {
      transform: scale(1.8);
    }
  }
}
