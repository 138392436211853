@use "../colors" as *;

.patient-feedback {
  text-align: left;
	max-width: 800px;
	background: #f0f0f0;
	border-radius: 1rem;
	padding: 1rem;
	border: 2px solid $primary-1-color;
	gap: 0.5em;
	display: flex;
	flex-direction: column;
	grid-template-columns: 1fr;

	h3 {
		margin: 0;
		margin-bottom: 0.5rem;
	}

  a {
    color: unset;
    text-decoration: none;
  }
}

.feedback-request-item {
	border: 1px solid $primary-1-color;
	border-radius: 1rem;
	display: flex;
	gap: 0.5rem;
	background: white;
	padding: 0.5em;
	width: auto;

	h4 {
		margin: 0;
	}

	.date {
		margin: 0;
		margin-left: auto;
	}
}

.feedback-request-item:hover {
	cursor: pointer;
}
	
