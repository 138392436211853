.checkbox-list-container {
  .checkbox-list-label {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    margin: 1.5em 0 0.5em;
  }
  .checkbox-list-item {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin: 0.25rem;
  }
}
