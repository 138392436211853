@use "../typography" as *;

.routine-card {
  width: 27.75rem;
  height: 11.5rem;
  display: flex;
  min-width: 0;
  border: 2px solid #dddddd;
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  .routine-photo {
    height: 100%;
    max-width: 10rem;
    .actual-photo {
      display: flex !important;
      width: 10rem;
      height: 100%;
      overflow: hidden;
      justify-content: center;
      img {
        height: 100%;
        display: block;
      }
    }
  }
  .routine-data {
    height: 100%;
    padding: 1rem 1.5rem;
    overflow: hidden;
    h4 {
      font-size: $large-text-size;
      margin: 0 0 1rem 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .routine-exercises {
      display: flex;
      overflow: hidden;
      flex-wrap: wrap;
      align-content: flex-start;
      height: calc(100% - 1rem - $large-text-size);
      .routine-exercise {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: min-content;
        font-size: 0.75rem;
        font-weight: 400;
        padding: 0.5rem 1rem;
        background-color: #f0f0f0;
        margin: 0 0 0.5rem 0;
        max-width: 100%;
        border-radius: 999rem;
      }
    }
  }
  .routine-buttons {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 0.5rem 0.5rem rgba(255, 255, 255, 0.8);
    padding: 0.5rem;
    .top-row {
      display: flex;
      justify-content: center;
      text-align: center;
      gap: 1rem;
      span {
        display: block;
        width: 1.5rem;
        border-radius: 0.25rem;
        &:hover {
          background-color: #dddddddd;
          box-shadow: 0 0 0.5rem 0.5rem #dddddddd;
        }
      }
    }
    .transparent {
      background-color: transparent;
      &:hover {
        background-color: #dddddddd;
        box-shadow: 0 0 0.25rem 0.25rem #dddddddd;
      }
    }
  }
  .routine-cover {
    position: absolute;
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -10px;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
