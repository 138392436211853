.modal {
  /* variables */
  $left-indent: 16.75rem; //268px // from grid-template-columns of .sidebar-layout
  $padding-block: 2.5rem; //42px
  $padding-inline: 3rem; //48px

  /* display */
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 30, 91, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 99;

  /* box-model */
  // border: 0;
  // border-radius: 1rem;
  // height: fit-content;
  // max-height: calc(100% - $padding-block * 2);
  // padding: $padding-block $padding-inline;
  // width: fit-content;
  // max-width: calc(100% - $left-indent - $padding-inline * 2);

  // z-index: 99;
  .modal-close-button {
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    height: 1rem;
    cursor: pointer;
  }

  .modal-content {
    width: fit-content;
    height: fit-content;
    border-radius: 1rem;
    background-color: white;
    padding: $padding-block $padding-inline;
    max-height: calc(100% - $padding-block * 2);
    max-width: calc(100% - $left-indent - $padding-inline * 2);
    overflow: auto;
  }

  /* children */
  menu {
    /* display */
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    gap: 1.5rem; //24px

    /* box-model */
    margin: 0;
    padding: 0;

    /* children */
    button {
      /* box-model */
      margin: 0;
      width: fit-content;
      min-width: 7rem; //112px
    }
  }

  &::backdrop {
    background: rgba(0, 30, 91, 0.5);
    left: $left-indent;
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-start;

    .minimal {
      text-align: center;
    }

    .transparent {
      font-size: 1.25rem;
    }
  }
}