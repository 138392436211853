.assessment {
	display: grid;
	grid-template-columns: 60% 1fr;
	width: 100%;
	
	h1 {
		grid-column: 1 / span 2;
	}

	.assessment-replay {
		width: 100%;
		min-height: 360px;
		min-width: 640px;
		display: flex;
		align-content: center;
		justify-content: center;
	}
}

.assessments-container {
	display: flex;
	gap: 1em;

	.patient-feedback {
		min-width: 45%;
	}
}

.assessment-table {
  width: 100%;

  th {
    text-align: left;
  }

	td.value {

		text-align: center;
	}

}

.replay-button {
	margin: 0;
	width: 50px;
	height: 25px;

	p {
		width: unset;
		margin: 0;
	}
}

.assessment-recommendations {
  display: grid;
	gap: 2em;
  grid-template-columns: repeat(2, 1fr);

  .item {
    display: flex;

    p,
    h2 {
      margin: 0;
    }

    padding: 1.5em 2em;
    border: 1px solid #dddddd;

    button {
			margin-left: auto;
      .span {
        color: white;
      }
      min-width: unset;
      min-height: unset;
      display: inline-block;
      flex-basis: 20%;
    }
  }
}

.assessment-item {
	p {
		margin: 0;
		margin-left: auto;
	}
}

*[data-problem] {
	font-weight: bold;
	background: red;
}

*[data-okay] {
	background: green;
}
