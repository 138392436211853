@use "../colors";

.floating-action-button {
  font-size: 1.5rem;
  width: auto;
  max-width: 2.5em;
  height: 2em;
  display: flex;
  border-radius: 0.5em;
  cursor: pointer;
  background-color: colors.$primary-base-color;
  transition: all 0.5s ease-in-out;
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 1.25em 1.5em;
  .floating-action-button-hover-content {
    overflow: hidden;
    width: auto;
    display: block;
    white-space: nowrap;
    max-width: 0.5em;
    transition: all 0.5s ease-in-out;
  }
  &:hover {
    max-width: 11em;
    .floating-action-button-hover-content {
      max-width: 100%;
    }
  }
  .floating-action-button-content {
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: center;
  }
}

.fixed {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
}
