@use "../typography" as *;
@use "../colors" as *;

$button-main-background-color: $primary-base-color;
$button-main-text-color: white;
$button-min-width: 15.5rem;

.button-component {
  display: block;
  min-width: $button-min-width; //248px
  min-height: 3.5rem; //56px
  background-color: $button-main-background-color;
  color: $button-main-text-color;
  font-size: $large-text-size;
  border: none;
  border-radius: 1rem;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  &:hover {
    background-color: darken($button-main-background-color, 7%);
  }
  &.disabled {
    background: #d5dffd;
  }
  &.transparent {
    display: inline-block;
    color: $button-main-background-color;
    background-color: $button-main-text-color;
    margin: 0;
    min-width: 0;
    min-height: auto;
    font-size: $subtext-size;
    padding: 0.5rem 2rem;
    p {
      margin: 0;
    }
    &:hover {
      background-color: #dddddd;
    }
  }
  &.fancy {
    background-color: $primary-1-color;
    border-left: 0.5rem solid $button-main-text-color; // 8px
    border-radius: 0.25rem; // 4px
    .button-content {
      margin: 0.75rem auto; //12px
    }
  }
  &.warning {
    color: white !important;
    background-color: #ee0000;
    p {
      margin: 0;
    }
    &:hover {
      background-color: #ca0000;
    }
  }
  &.minimal {
    margin: 0 0 0 1em;
    min-width: 13.125rem; //210px
    min-height: 0;
    height: 3.125rem; //50px
    padding: 0 0.7em;
    font-size: $large-text-size;
    font-weight: 500;
    text-align: start;
    p {
      margin: 0;
    }
  }
  &.simple {
    text-align: center;
  }
}
