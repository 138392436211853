@use "../typography" as *;

$status-height: 4rem;
$picture-height: 5rem; //80px

$card-width: 20.375rem; //326px

.patient-card {
  position: relative;
  font-size: $subtext-size;
  width: $card-width;
  border: 2px solid #dddddd;
  border-radius: 1em;
  cursor: pointer;

  .delete-patient__btn {
    width: 1.8rem;
    position: absolute;
    z-index: 20;
    right: 0.5rem;
    top: 0.5rem;
    color: white;
    display: none;
    animation: fadeIn 500ms;
  }

  .status-color {
    border-radius: 1em 1em 0 0;
    width: 100%;
    height: $status-height;
    background-color: #443c3c;
  }
  .patient-picture {
    margin: 0;
    position: absolute;
    left: 50%;
    height: $picture-height;
    width: $picture-height;
    font-size: $picture-height;
    top: $status-height;
    transform: translate(-50%, -50%);
  }
  .patient-content {
    text-align: center;
    display: flex;
    flex-direction: column;
		margin-bottom: 1.2rem;
    h4 {
      font-size: $large-text-size;
      margin: 2.4em 0 0 0;
    }
    .patient-email {
      font-weight: 400;
    }
    .subtext {
      font-size: 0.625rem;
      margin: 0;
      opacity: 0.75;
    }
  }

  &:hover {
    .delete-patient__btn {
      display: initial;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
