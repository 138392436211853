.mini-routine-card {
  border-radius: 1rem;
  width: 100%;
  min-width: 0;
  display: flex;
  justify-content: center;
  height: 2rem;
  font-size: 1rem;
  overflow: hidden;
  padding: 0 0.5rem;
  .routine-data {
    height: 100%;
    overflow: hidden;
    // width: calc(100% - 2rem);
    display: flex;
    align-items: center;
  }
  h5 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
  .routine-photo {
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.25rem;
    .actual-photo {
      display: flex !important;
      flex-direction: column;
      max-width: 2rem;
      overflow: hidden;
      justify-content: center;
    }
  }
}
