@use "../colors";

.upload-file-modal {
  min-width: fit-content;
  .upload-file-container {
    width: 50vw;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #dddddd;
    border-radius: 0.5rem;
    padding: 4rem 2rem;
    position: relative;
    .material-icons-outlined {
      color: #888888;
      font-size: 3rem;
    }
    .upload-label {
      text-align: center;
      .upload-label-main {
        color: colors.$primary-base-color;
      }
      &:hover {
        .upload-label-main {
          color: darken(colors.$primary-base-color, 15%);
        }
      }
      .upload-input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        display: block;
        cursor: pointer;
      }
    }
  }
  .get-filename {
    margin-top: 1rem;
    display: block;
    .file-name-input {
      border: none;
      outline: none;
      font-family: inherit;
      font-size: 1.25rem;
      border-bottom: 1px solid #888888;
      margin-left: 1rem;
      margin-top: 0.5rem;
      transition: all 0.25s ease-in-out;
      &:focus {
        background-color: #f3f3f3;
        box-shadow: 0 0 3px 3px #f3f3f3;
      }
    }
  }
  .file-upload-buttons {
    margin-top: 1rem;
  }
}
